import 'lazysizes';

import Account from './modules/account'; // secondary import
import Carousel from './modules/carousel';
import Cart from './modules/cart';
import ContactForm from './modules/contact-form'; // secondary import
import FilterSelect from './modules/filter-select';
import GalleryWall from './modules/gallery-wall'; // separate bundle on wall pages - still gets imported from the product selector module...
import Navigation from './modules/navigation';
import NewsLetterForm from './modules/newsletter-form';
import Posters from './modules/posters';
import ProductSelector from './modules/product-selector'; // perhaps separate bundle on product pages?
import Search from './modules/search'; // secondary import - perhaps separate bundle on search page?
import SlideshowModal from './modules/slideshow-modal'; // perhaps separate bundle on product pages?
import Utilities from './modules/utilities';
import Alert from './modules/alert';
import CampaignModal from './modules/campaign-modal';
import Clipboard from './modules/clipboard';
import NewsletterModal from './modules/newsletter-modal';
import NewsletterPage from './modules/newsletter-page'; // secondary import
import Timer from './modules/timer';
import Ecommerce from './modules/ecommerce';
import Checkout from './modules/checkout';
import FacebookConversionService from './modules/facebook-conversion-service';
import LocationRedirectModal from './modules/location-redirect-modal';
import Elevio from './modules/elevio';
import Modal from './modules/modal';
import TextExpand from './modules/text-expand';
import CampaignLadder from './modules/campaign-ladder';
import DynamicContent from './modules/dynamic-content';
// import InputValidation from './modules/input-validation'; // member page

document.addEventListener('DOMContentLoaded', () => {
  Utilities.log('init Printler.com');

  // init modules
  Account.init();
  Navigation.init();
  Carousel.init();
  SlideshowModal.init(); // product page
  FilterSelect.init();
  Posters.init();
  ProductSelector.init(); // product page
  Cart.init();
  NewsLetterForm.init();
  Search.init();
  GalleryWall.init(); // wall pages
  ContactForm.init();
  Alert.init();
  CampaignModal.init();
  Clipboard.init();
  NewsletterModal.init();
  NewsletterPage.init();
  Timer.init();
  Ecommerce.init();
  Checkout.init();
  FacebookConversionService.init();
  LocationRedirectModal.init();
  Elevio.init();
  Modal.init();
  TextExpand.init();
  CampaignLadder.init();
    //InputValidation.init(); // member page
  DynamicContent.init();

  window.addEventListener('CookiebotOnAccept', () => {
    Ecommerce.init();
  }, false);

  // validate forms - Used on contact form and giftcard page
  // Utilities.validateForms();

  // automatically scroll to results on pagination link click
  Utilities.setupPaginationAutoScroll();

  // example lazy load module - seems to break webpack assets file when used?
  //Utilities.importNewsLetterModuleAsync().then((module: any) => {
  //  module.default.init();
    //});


});
