/**
 * Campaign ladder logic
 */
class CampaignLadder {
  static Banners = document.querySelectorAll('.js-ladder-progress-bar');
  static Banner = document.getElementById('jsLadderProgressBar');
  static Discounts = CampaignLadder.Banner?.querySelectorAll('.js-ladder-discount');
  static MaxProducts = 5; // Max products to display as "5+"

  /**
   * Init
   */
  static init(): void {
    if (!CampaignLadder.Banner || CampaignLadder.Banners.length == 0) {
      return;
    }
  }

  /**
   * Function to update the product count text
   * @param productCount - set in cart.ts
   */
  static updateProductCount(productCount: number): void {
    if (!CampaignLadder.Banner || CampaignLadder.Banners.length == 0) {
      return;
    }

    CampaignLadder.Banners.forEach((banner) => { 
        const productCountStep: HTMLElement = banner.querySelector('.product-count');
        productCountStep.textContent = productCount > CampaignLadder.MaxProducts ? '5+' : `${productCount}`;
        CampaignLadder.setActiveStep(productCount);  // Update active step based on the new product count
      }
    );
  }

  /**
   * Function to calculate the active step based on the current product count
   * @param productCount
   * @returns
   */
  static calculateStepByProductCount(productCount: number): number {
    if (productCount <= 1) return 0;
    if (productCount >= CampaignLadder.MaxProducts) return CampaignLadder.Discounts.length - 1; // Last step for max or more products
    return Math.min(productCount - 1, CampaignLadder.Discounts.length - 1);  // Map product count to steps
  }

  /**
   * Set the active step based on the current product count
   * @param productCount
   */
  static setActiveStep(productCount: number): void {
    const activeStepIndex = CampaignLadder.calculateStepByProductCount(productCount);

    CampaignLadder.Banners.forEach((banner) => {
      const steps = banner.querySelectorAll('.step:not(.product-count)');

      steps.forEach((step, index) => {
        step.classList.remove('active', 'completed');
        if (index < activeStepIndex) {
          step.classList.add('completed');
        } else if (index === activeStepIndex) {
          step.classList.add('active');
        }
      });
    });
  }

  static expandBanner(): void {
    const campaignBanner: HTMLElement = document.getElementById('campaignBanner');
    campaignBanner.classList.add('expand');

    setTimeout(() => {
      campaignBanner.classList.remove('expand');
    }, 3000);
  }
}

export default CampaignLadder;