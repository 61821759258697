import {ApiEndpoints, EntityType} from '../enums';
import {Photo} from '../models';
import UrlService from './url-service';
import Utilities from './utilities';

class FavouritesService {
  /**
   * Save favourite
   * @param id - id of content
   * @param entityType - poster or photographer enum
   */
  static async add(id: number, entityType: EntityType): Promise<boolean> {
    if (id === 0) {
      return false;
    }

    const requestOptions = {
      method: 'POST',
    };

    const requestUrl = UrlService.getApiUrl(ApiEndpoints.Favourite, entityType, {
      id: id,
    });
    
    console.log('add fav', requestUrl);
    
    //try {
      const response = await fetch(requestUrl, requestOptions);
      console.log(response);
      return response.ok;
    /*} catch (error) {
      if (error) {
        console.log(error);
        Utilities.log(error.message);
        return false;
      }
    }*/
  }

  /**
   * Delete favourite
   * @param id - id of content
   * @param entityType - poster or photographer enum
   */
  static async delete(id: number, entityType: EntityType): Promise<boolean> {
    if (id === 0) {
      return false;
    }

    const requestOptions = {
      method: 'DELETE',
    };

    const requestUrl = UrlService.getApiUrl(ApiEndpoints.Favourite, entityType, {
      id: id,
    });

    try {
      const response = await fetch(requestUrl, requestOptions);
      return response.ok;
    } catch (error) {
      if (error) {
        Utilities.log(error.message);
        return false;
      }
    }
  }

  /**
   * Get all by type
   * @param entityType - poster or photographer enum
   */
  static async getAllByTypeAsync(entityType: EntityType): Promise<number[]> {
    const requestOptions = {
      method: 'GET',
    };

    const requestUrl = UrlService.getApiUrl(ApiEndpoints.Favourites, entityType);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.json();
    } catch (error) {
      if (error) {
        Utilities.log(error);
        return null;
      }
    }
  }

  /**
   * Retrieves all favorites photos for the current user.
   * @returns A promise that resolves to a list of favorite photos for the user.
   */
  static async getAllPhotos(): Promise<Photo[]> {
    const requestOptions = {
      method: 'GET',
    };

    const requestUrl = UrlService.getApiUrl(ApiEndpoints.Favourite, EntityType.Poster);

    try {
      const response = await fetch(requestUrl, requestOptions);
      return await response.json();
    } catch (error) {
      if (error) {
        Utilities.log(error);
        return null;
      }
    }
  }

  /**
   * Get Rendered HTML
   * */
  static async getRenderedHtml(): Promise<string> {
    const requestOptions = {
      method: 'GET',
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.RenderFavourites);
    const response = await fetch(requestUrl, requestOptions);
    if (response.ok) {
      return await response.text();
    } else {
      Utilities.log('Error fetching favourites html');
      return null;
    }
  }
}

export default FavouritesService;
