import { Dictionary } from 'lodash';

import { ApiEndpoints } from '../enums';
import UrlService from './url-service';

/**
 * Service for retrieving dictionary translations from Umbraco.
 */
export class DictionaryService {
  private static _dictionary: Dictionary<string> = {};
  private static _retrieved = false;
  private static _retrievalPromise: Promise<void> = null;

  /**
   * Translate a given Umbraco dictionary key.
   * If no value could be found for the key, the key is returned instead.
   * @param key The key to get translation for
   * @param defaultValue (optional) Default value if no value is present for the translation
   * @param root (optional) Dictionary root. (Defaults to WallPlanner)
   * @returns A promise that resolves to the translated key
   */
  static async translate(key: string, defaultValue?: string, root = 'WallPlanner'): Promise<string> {
    try {
      await this._ensureRetrieved(root);
      return this._dictionary[key] || (typeof defaultValue === 'string' ? defaultValue : key);
    }
    catch (error) {
      return defaultValue;
    }
  }

  /**
   * Ensures that translations has been retrieved from the server before a translation is performed.
   * Will fetch translations from selected root in dictionary.
   */
  private static _ensureRetrieved(root: string): Promise<void> {
    if (this._retrieved) {
      return Promise.resolve();
    } else if (this._retrievalPromise) {
      return this._retrievalPromise;
    }

    return (this._retrievalPromise = new Promise<void>((resolve, reject) => {
      if (this._retrieved) {
        resolve();
      } else if (this._retrievalPromise) {
        this._retrievalPromise.then(() => resolve(), reject);
      } else {
        const url = UrlService.getApiRequestUrl(ApiEndpoints.Dictionary, 'get', {
          root: root,
        });

        const prom = fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        });

        prom.then(response => {
          response.json().then(json => {
            this._dictionary = json;
            this._retrieved = true;
            resolve();
          }, reject);
        }, reject);
      }
    }));
  }
}
