
/**
 * DynamicContent logic
 */
class DynamicContent {

    static init(): void {
        // Load content for all elements with dynamic-content attribute
        const dynamicElements = document.querySelectorAll<HTMLElement>('[dynamic-content]');
        dynamicElements.forEach((element) => {
            this.loadDynamicContent(element);
        });
    }

    // eslint-disable-next-line 
    static parseDynamicContentAttribute(attributeValue: string): Record<string, any> {
        try {
            return new Function(`return ${attributeValue}`)();
        } catch (error) {
            console.error('Error parsing dynamic-content attribute:', error);
            return {};
        }
    }

    // Function to dynamically load content
    static async loadDynamicContent(element: HTMLElement): Promise<void> {
        const dynamicContentAttr = element.getAttribute('dynamic-content');
        if (!dynamicContentAttr) return;

        const config = this.parseDynamicContentAttribute(dynamicContentAttr);

        console.log(dynamicContentAttr);

        if (!config.url) {
            console.error('No URL specified in dynamic-content attribute:', dynamicContentAttr);
            return;
        }

        try {
            const response = await fetch(config.url, {
                method: config.method || 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error(`Failed to fetch: ${response.status} ${response.statusText}`);
                return;
            }

            const content = await response.text();
            let targetElement: HTMLElement | null = null;

            if (config.target === 'self' || !config.target) {
                targetElement = element;
            } else {
                targetElement = document.querySelector(config.target);
            }

            if (!targetElement) {
                console.error(`Target element not found: ${config.target}`);
                return;
            }

            // Apply the content based on the mode
            switch (config.mode) {
                case 'content':
                    targetElement.innerHTML = content;
                    break;
                case 'replace':
                    targetElement.outerHTML = content;
                    break;
                case 'append':
                    targetElement.insertAdjacentHTML('beforeend', content);
                    break;
                case 'insert':
                default:
                    targetElement.insertAdjacentHTML('afterbegin', content);
                    break;
            }
        } catch (error) {
            console.error('Error loading dynamic content:', error);
        }
    }
}

export default DynamicContent;