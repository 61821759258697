import Utilities from './utilities';

/**
 * Initializes Search logic
 */
class Search {
  static init(): void {
    const searchForm: HTMLFormElement = document.querySelector('.js-search-form');
    const tabs: HTMLElement = document.querySelector('.js-search-tabs');
    const suggestionsContainer: HTMLElement = searchForm?.querySelector('.js-suggestions');
    const suggestionsClose: HTMLElement = searchForm?.querySelector('.js-suggestions-close');
    const suggestionsLinks: NodeListOf<HTMLElement> = suggestionsContainer?.querySelectorAll('.js-suggestions-list li');
    const artistsResultContainer: HTMLElement = document?.querySelector('.js-artists-results');
    const pagesResultContainer: HTMLElement = document?.querySelector('.js-pages-results');
    
    if (!searchForm || searchForm.length === 0) {
      return;
    }

    const searchInput: HTMLInputElement = searchForm.querySelector('input[name=q]');

    // Add a focus event listener
    searchInput.addEventListener('focus', () => {
      if (!suggestionsContainer.classList.contains('is-active'))
        suggestionsContainer.classList.add('is-active');
    });

    // close suggestions
    suggestionsClose.addEventListener('click', () => {
      if (suggestionsContainer.classList.contains('is-active'))
        suggestionsContainer.classList.remove('is-active');
    });

    // add form submit event listener
    searchForm.addEventListener('submit', (e: Event) => {
      this.handleSearchSubmit(e, searchForm);
    }, true);

    // add suggestions click event listener
    suggestionsLinks?.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        searchInput.value = link.innerText;
        const bodyElm: HTMLElement = document.querySelector('body');
        bodyElm.classList.add('is-loading');
        searchForm.submit();
        suggestionsClose.click();
        // add value to search input and submit form
      }, true);
    });

    // populate results async - deactivated for now
    /*
    if (postersResultContainer != null) {
      this.fetchPosters(postersResultContainer);
    }
    */

    // if artist search selected
    if (artistsResultContainer != null) {
       this.fetchArtists(artistsResultContainer);
    }

    // if content search selected
    if (pagesResultContainer != null) {
      this.fetchPages(pagesResultContainer);
    }

    // add tab click event listener - TODO: extract tab functionality to separate module?
    if (tabs) {
      const tabLinks: NodeListOf<HTMLLinkElement> = tabs.querySelectorAll('.nav-link');
      Array.from(tabLinks).forEach(tab => {
        tab.addEventListener('click', (e: Event) => {
          e.preventDefault();
          this.displayContent(tab, tab.getAttribute('href')); //

          // reload page with correct search content state
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('searchContent', `${tab.dataset.type}`);

          // hide current results and display spinner
          const bodyElm: HTMLElement = document.querySelector('body');
          bodyElm.classList.add('is-loading');

          document.location.href = `?${searchParams.toString()}`;
        }, true);
      });
    }
  }

  static showSuggestions(suggestionsContainer: HTMLElement): void {
    // console.log('container:', suggestionsContainer);

    // display suggestions
    suggestionsContainer.setAttribute('is-active', 'className');
    suggestionsContainer.classList.add('is-active');
  }

  /**
   * Search form submit handler
   * @param event - change event
   * @param form - search form
   */
  static handleSearchSubmit(event: Event, form: HTMLFormElement): void {
    event.preventDefault();

    // execute recaptcha
    // window.grecaptcha.execute();

    const queryInput: HTMLInputElement = form.querySelector('input[name=q]');
    const searchParams = new URLSearchParams(window.location.search);
    const existingQueryValue = queryInput.dataset.value;

    // same query - new page
    if (queryInput.value == existingQueryValue) {
      searchParams.set('q', existingQueryValue);
      document.location.href = `?${searchParams.toString()}`;
    }

    // new query
    searchParams.set('q', queryInput.value);
    searchParams.delete('page');

    // hide current results and display spinner
    const bodyElm: HTMLElement = document.querySelector('body');
    bodyElm.classList.add('is-loading');

    document.location.href = `?${searchParams.toString()}`;
  }

  /**
 * Display tab content
 * @param tab
 * @param contentId
 */
  static displayContent(tab: HTMLLinkElement, contentId: string): void {
    const _content: NodeListOf<HTMLElement> = document.querySelectorAll('.js-tab-pane');
    const _tabs: NodeListOf<HTMLElement> = document.querySelectorAll('.js-search-tabs .nav-link');
    // clear existing active tab
    Array.from(_tabs).forEach(item => {
      item.classList.remove('active');
    });
    tab.classList.add('active');
    // clear all content
    Array.from(_content).forEach(item => {
      item.classList.remove(...['active', 'show']);
    });
    const content: HTMLElement = document.querySelector(contentId);
    
    content.classList.add(...['active', 'show']);
    // set url param to contain correct search content state
    window.history.replaceState('', '', Utilities.updateURLParameter(window.location.href, 'searchContent', tab.getAttribute('data-type')));
  }
  /**
   * Artworks - deactivated for now
   * */
  /*
  static fetchPosters(container: HTMLElement): void {
    const referrer: string = container.dataset.referrer;
    const query: string = container.dataset.query;
    const orientation: string = container.dataset.orientation;
    const baseUrl = '/umbraco/printler/photos/search';
    let fetchUrl = `${baseUrl}?referrer=${referrer}&locale=${window.Printler.Locale}&q=${query}&order=score`;
    if (orientation) {
      fetchUrl += `&orientation=${orientation}`;
    }
    console.log('FETCH: ', fetchUrl);
    
    // make request
    this.fetchResults(fetchUrl, container);
  }
  */
  /**
   * Artists search
   * */
  static fetchArtists(container: HTMLElement): void {
    const referrer: string = container.dataset.referrer;
    const query: string = container.dataset.query;
    const noResult: string = container.dataset.noResult;
    const baseUrl = '/umbraco/printler/members/search/';
    const fetchUrl = `${baseUrl}?referrer=${referrer}&locale=${window.Printler.Locale}&q=${query}&order=score&noResult=${noResult}`;

    // make request
    this.fetchResults(fetchUrl, container);
  }

  /**
   * Pages search
   * */
  static fetchPages(container: HTMLElement): void {
    const referrer: string = container.dataset.referrer;
    const query: string = container.dataset.query;
    const noResult: string = container.dataset.noResult;
    const baseUrl = '/umbraco/printler/pages/search/';
    const fetchUrl = `${baseUrl}?referrer=${referrer}&locale=${window.Printler.Locale}&q=${query}&order=score&noResult=${noResult}`;

    // make request
    this.fetchResults(fetchUrl, container);
  }
  
  /**
   * Fetch results - deactivated for now
   * @param url
   * @param container
   */
  static fetchResults(url: string, container: HTMLElement): void {
    fetch(`${url}`)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          container.style.display = 'none';
          throw new Error('Error fetching search results.');
        }
      })
      .then(result => {
        container.innerHTML = result;
      });
  }
}

export default Search;
