import Clipboard from './clipboard';
import Utilities from './utilities';
import Ecommerce from './ecommerce';
import VoyadoService from './voyado-service';

/**
 * The newsletter signup page
 */
class NewsLetterPage {
  static ErrorReason: string;
  static ShowError = false;
  /*
   * Render newsletter form
   */
  static init(): Promise<void> {
    const signup: HTMLElement = document.querySelector('.js-newsletter-page-form-container');

    if (!signup) {
      return;
    }

    const stepTriggerBtn: HTMLButtonElement = signup.querySelector('.js-newsletter-page-form-step-btn');
    const emailInputField = signup.querySelector('#emailInput');

   
    // trigger button click on enter keyup
    emailInputField.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        stepTriggerBtn.click();
      }
    });

    stepTriggerBtn.addEventListener('click', () => {
      const signupPage: HTMLElement = document.querySelector('.js-signup-page');
      const formContainer: HTMLElement = document.querySelector('#newsletterApiForm');
      const emailInput: HTMLInputElement = formContainer.querySelector('#emailInput');
      const countryInput: HTMLInputElement = formContainer.querySelector('#countryInput');
      const spinner: HTMLElement = signup.querySelector('.js-newsletter-page-spinner');
      const validation: HTMLElement = signup.querySelector('.js-validation-error');
      const step1Content: NodeListOf<HTMLElement> = signupPage.querySelectorAll('.js-newsletter-page-signup-step1');
      const step2Content: NodeListOf<HTMLElement> = signupPage.querySelectorAll('.js-newsletter-page-signup-step2');
      const signupSourceTag = formContainer.dataset.source ?? 'page';

      // validate inputs
      if (NewsLetterPage.validate(formContainer, emailInput, countryInput)) {
        // hide initial content
        step1Content.forEach(elm => elm.classList.add('d-none'));
        validation.classList.add('d-none');

        // show spinner 
        spinner.classList.remove('is-hidden');

        // reset error message
        NewsLetterPage.ErrorReason = 'An error ocurred when submitting the form. Please validate that your email was entered correct.';

        // call api and register subscriber
        VoyadoService.addVoyadoContactAsync(emailInput.value, countryInput.value, window.Printler.StoreId, signupSourceTag).then(result => {
          if (result.ok) {
            Clipboard.init();

            setTimeout(() => {
              spinner.classList.add('is-hidden');
              // show step 2
              step2Content.forEach(elm => elm.classList.remove('d-none'));
            }, 300);


            // on form submit push sign up event to GTM
            Ecommerce.pushNewsLetterSignupEvent(`Newsletter ${signupSourceTag}`);
          } else {
            Utilities.error(NewsLetterPage.ErrorReason);
            setTimeout(() => {
              spinner.classList.add('is-hidden');
              validation.innerHTML = NewsLetterPage.ErrorReason;
              validation.classList.remove('d-none');
              step1Content.forEach(elm => elm.classList.remove('d-none'));
            }, 200);
          }
        });
      }
      else {
        Utilities.error('Missing fields.');
        validation.innerHTML = validation.getAttribute('data-invalid');
        validation.classList.remove('d-none');
      }
    });
  }

  /**
   * Validate fields
   * @param formContainer
   */
  static validate(formContainer: HTMLElement, emailInput: HTMLInputElement, countryInput: HTMLInputElement): boolean {
    if (emailInput.value && countryInput.value && formContainer) {
      return true;
    }

    return false;
  }
}

export default NewsLetterPage;
